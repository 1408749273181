import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  isRouteErrorResponse,
  useNavigation,
} from "@remix-run/react";
import type { LinksFunction, MetaFunction } from "@remix-run/cloudflare";

import "./tailwind.css";
import { NextUIProvider, Spinner } from "@nextui-org/react";
import { ThemeProvider } from "next-themes";
import { Toaster } from "react-hot-toast";
import { UserProvider } from "./stores/user";
import posthog from "posthog-js";
import { SearchProvider } from "./stores/search";
import Footer from "./components/footer";
import { useEffect } from "react";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no, minimum-scale=1, maximum-scale=1, viewport-fit=cover, theme-color=light"
        />
        <Meta />
        <Links />
      </head>

      <body className="font-sans">
        <NextUIProvider locale="en-GB">
          <ThemeProvider
            attribute="class"
            defaultTheme="light"
            forcedTheme="light"
          >
            <UserProvider>
              <SearchProvider>
                <div className="min-h-screen flex flex-col">
                  {children}
                  <Footer />
                </div>
              </SearchProvider>
            </UserProvider>
            <Toaster />
          </ThemeProvider>
        </NextUIProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const meta: MetaFunction = ({ error }) => {
  return [{ title: error ? "Oops!" : "Tribe" }];
};

function App() {
  const navigation = useNavigation();

  if (navigation.state !== "idle") {
    return (
      <div className="w-screen h-screen absolute top-0 left-0 bg-background z-50 flex items-center justify-center animate-fade animate-ease-in animate-duration-[10ms]">
        <Spinner />
      </div>
    );
  }

  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <div className="flex-1 flex flex-col items-center justify-center gap-4">
      {isRouteErrorResponse(error) ? (
        <>
          <h2 className="text-center font-extrabold text-7xl text-default-500">
            {error.status}
          </h2>
          <p className="text-center text-2xl">{error.statusText}</p>
        </>
      ) : error instanceof Error ? (
        <>
          <h2 className="text-center font-extrabold text-7xl text-default-500">
            {error.message}
          </h2>
          <p className="text-center text-2xl">The stack trace is:</p>
          <pre className="text-center text-2xl">{error.stack}</pre>
        </>
      ) : (
        <h2 className="text-center font-extrabold text-7xl text-default-500">
          Unknown Error
        </h2>
      )}
    </div>
  );
}