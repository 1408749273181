import posthog from "posthog-js";
import { HiSparkles } from "react-icons/hi2";
import { Link } from "@nextui-org/link";
import { track } from "~/utils/track";

export default function Footer() {
  return (
    <div className="flex flex-col px-4 sm:px-6 py-8 gap-6">
      <div className="flex flex-col sm:flex-row justify-between gap-8">
        <div className="">
          <span className="block text-default-500 font-black text-3xl">
            tribe
          </span>
          <p className="text-default-500">
            Find any service expert with{" "}
            <span className="text-primary font-bold inline-flex items-center">
              AI <HiSparkles className="ml-1" />
            </span>{" "}
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-6 sm:gap-8 sm:mr-8">
          <div>
            <h5 className="mb-2">Legal</h5>
            <div>
              <Link
                color="foreground"
                size="sm"
                underline="hover"
                href="/terms"
              >
                Terms & Conditions
              </Link>
            </div>
            <div>
              <Link
                color="foreground"
                size="sm"
                underline="hover"
                href="/privacy"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <div>
            <h5 className="mb-2 font-medium">Social</h5>
            <div>
              <Link
                color="foreground"
                size="sm"
                isExternal
                showAnchorIcon
                underline="hover"
                onPress={() => {
                  posthog.capture("footer_social_clicked", {
                    type: "instagram",
                  });
                  track("footer_social_clicked", {
                    type: "instagram",
                  });
                }}
                href="https://www.instagram.com/tribe.best"
              >
                Instagram
              </Link>
            </div>
            <div>
              <Link
                color="foreground"
                size="sm"
                isExternal
                showAnchorIcon
                underline="hover"
                onPress={() => {
                  posthog.capture("footer_social_clicked", {
                    type: "linkedin",
                  });
                  track("footer_social_clicked", {
                    type: "linkedin",
                  });
                }}
                href="https://in.linkedin.com/company/tribe-best"
              >
                LinkedIn
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <p className="block text-xs text-default-500">
          © Speakleap Technologies Private Limited
        </p>
      </div>
    </div>
  );
}
